import React, { createContext, useState } from "react";
import isEqual from "lodash/isEqual";

export const CartContext = createContext({
  items: [],
  setItems: (prevState) => prevState,
  currentUser: [],
  setCurrentUser: (prevState) => prevState,
  getItemsCount: () => {
    return 0;
  },
  getCurrentUser: () => {
    return null;
  },
  addItemToCart: (product) => console.log("ff", product),
  getTotalPrice: () => {
    return 0;
  },
  removeItem: () => console.log(""),
  removeAllItems: () => console.log(""),
  updateQuantityHandler: (newQty, product) => console.log("", newQty, product),
  updateCurrentUser: (newCurrentUser) => console.log("", newCurrentUser),
});

export function CartProvider(props) {
  const [items, setItems] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);

  const updateCurrentUser = (newCurrentUser) => {
    /*setCurrentUser((prevItems) => {
      return [...prevItems, newCurrentUser]
    })*/
    setCurrentUser((prevItems) => {
      return newCurrentUser;
    });
  };

  const updateQuantityHandler = (newQty, product, minus) => {
    setItems((prevItems) => {
      const currentItem = prevItems.find(
        (item) =>
          item.product.comment === product.comment &&
          item.key == product.key &&
          isEqual(
            item.product.categorieConfig?.sort((a, b) =>
              a.key.localeCompare(b.key)
            ),
            product.categorieConfig?.sort((a, b) => a.key.localeCompare(b.key))
          )
      );
      //console.log('currentItem ', currentItem);

      return prevItems.map((item) => {
        if (
          currentItem.product.comment === item.product.comment &&
          currentItem.key == item.key &&
          isEqual(
            item.product.categorieConfig?.sort((a, b) =>
              a.key.localeCompare(b.key)
            ),
            product.categorieConfig?.sort((a, b) => a.key.localeCompare(b.key))
          )
        ) {
          //console.log('heeeeeeeeeey test');
          item.qty = newQty;
          item.totalPrice += product.price * (minus ? -1 : 1);
        }
        return item;
      });
    });
  };

  function addItemToCart(product) {
    setItems((prevItems) => {
      const item = prevItems.find((item) => {
        return (
          item.product.comment === product.comment &&
          item.key == product.key &&
          isEqual(
            item.product.categorieConfig
              ?.sort((a, b) => a.key.localeCompare(b.key))
              .map((item) => ({
                ...item,
                value: item.value.sort((a, b) => a.localeCompare(b)), // Sort value array
              })),
            product.categorieConfig
              ?.sort((a, b) => a.key.localeCompare(b.key))
              .map((item) => ({
                ...item,
                value: item.value.sort((a, b) => a.localeCompare(b)), // Sort value array
              }))
          )
        );
      });

      if (!item) {
        return [
          ...prevItems,
          {
            key: product.key,
            qty: product.qty,
            product,
            totalPrice: product.price * product.qty,
          },
        ];
      } else {
        return prevItems.map((item) => {
          if (item.key == product.key) {
            item.qty += product.qty;
            item.totalPrice += product.price * product.qty;
          }
          return item;
        });
      }
    });
  }

  function removeItem(product) {
    //console.log("prrrrrrrrroduct ",product)
    setItems((prevItems) => {
      return prevItems.filter(
        (item) =>
          item.key !== product.key ||
          (item.key === product.key &&
            item.product.categorieConfig !== product.categorieConfig)
      );
    });
  }

  function removeAllItems() {
    setItems([]);
    setCurrentUser([]);
  }

  const getItemsCount = () => {
    return items.reduce((sum, item) => sum + item.qty, 0);
  };

  const getCurrentUser = () => {
    //console.log("======== getcurrentuer ",currentUser)
    return currentUser;
  };

  const getTotalPrice = () => {
    //console.log('getTotalPrice ');
    return items.reduce((sum, item) => sum + item.totalPrice, 0);
  };

  return (
    <CartContext.Provider
      value={{
        items,
        setItems,
        getItemsCount,
        currentUser,
        getCurrentUser,
        addItemToCart,
        getTotalPrice,
        updateCurrentUser,
        removeItem,
        removeAllItems,
        updateQuantityHandler,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}
