import { COLORS } from "../../constants/theme";
import { FONTS } from "../../constants/theme";
import React, { useState } from "react";
import "./style.css";

const CustomRadioButton = ({ data, updateSelectedConfigValues }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const isSelected = (value) => {
    let isSelected = false;

    selectedValues?.map((selectedValue) => {
      if (selectedValue.value === value) isSelected = true;
    });
    return isSelected;
  };

  return (
    <div
      style={{
        backgroundColor: "#1e1e1e", // Fond sombre pour le contraste
        borderRadius: 12,
        padding: 15,
        marginTop: 10,
        marginBottom: 30,
      }}
    >
      {data.length > 0 &&
        Object.keys(data[0]).map((key, index) => (
          <div key={index} style={{ marginBottom: 15 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <p
                style={{
                  color: "#f7da71", // Couleur dorée pour le texte
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: 5,
                }}
              >
                {data[0][key].title}
              </p>
              {data[0][key].required && (
                <span
                  style={{
                    color: "#1e1e1e",
                    backgroundColor: "#f7da71",
                    borderRadius: 12,
                    padding: "4px 8px",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Choix obligatoire
                </span>
              )}
            </div>
            {data[0][key]?.description?.length > 0 && (
              <p
                style={{
                  color: "rgb(205 205 205 / 79%)",
                  fontSize: "14px",
                  marginBottom: 10,
                }}
              >
                {data[0][key].description}
              </p>
            )}
            {data[0][key]?.data?.map((currValue) => (
              <button
                style={{
                  width: "100%",
                  borderRadius: 12,
                  border: `2px solid ${
                    isSelected(currValue.value) ? "#f7da71" : "#444"
                  }`,
                  backgroundColor: isSelected(currValue.value)
                    ? "#f7da71"
                    : "transparent",
                  color: isSelected(currValue.value) ? "#1e1e1e" : COLORS.white,
                  padding: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                  transition: "all 0.3s ease-in-out",
                  cursor: "pointer",
                  marginBottom: "15px",
                  fontWeight: "400",
                }}
                onClick={() => {
                  let customChoiceArray = [];

                  setSelectedValues((prevItems) => {
                    const item = prevItems.find((item) => {
                      return item.value === currValue.value;
                    });
                    if (data[0][key].maxItems > selectedValues.length) {
                      if (item) {
                        customChoiceArray = prevItems.filter(
                          (currentItem) => currentItem.value !== currValue.value
                        );
                        updateSelectedConfigValues(
                          key,
                          customChoiceArray,
                          data[0][key]?.required
                        );
                        return customChoiceArray;
                      }
                      customChoiceArray = [
                        ...prevItems.filter(
                          (currentItem) => currentItem.value !== currValue.value
                        ),
                        currValue,
                      ];
                      updateSelectedConfigValues(
                        key,
                        customChoiceArray,
                        data[0][key]?.required
                      );

                      return customChoiceArray;
                    } else {
                      if (item) {
                        customChoiceArray = prevItems.filter(
                          (currentItem) => currentItem.value !== currValue.value
                        );
                        updateSelectedConfigValues(
                          key,
                          customChoiceArray,
                          data[0][key]?.required
                        );

                        return customChoiceArray;
                      }
                      customChoiceArray = prevItems;
                      updateSelectedConfigValues(
                        key,
                        customChoiceArray,
                        data[0][key]?.required
                      );

                      return customChoiceArray;
                    }
                  });
                }}
                key={currValue.value + "_" + key}
              >
                <span>
                  {currValue.value}{" "}
                  {currValue.price > 0 && `+${currValue.price}Dhs`}
                </span>
                <div
                  style={{
                    width: 18,
                    height: 18,
                    borderRadius: "50%",
                    border: `2px solid ${
                      isSelected(currValue.value) ? "#1e1e1e" : COLORS.white
                    }`,
                    backgroundColor: isSelected(currValue.value)
                      ? "#1e1e1e"
                      : "transparent",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "all 0.3s ease-in-out",
                  }}
                >
                  {isSelected(currValue.value) && (
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        backgroundColor: "#f7da71",
                      }}
                    ></div>
                  )}
                </div>
              </button>
            ))}
          </div>
        ))}
    </div>
  );
};

export default CustomRadioButton;
