import { useNavigate } from "react-router-dom";

export const FooterHelper = () => {
  const navigate = useNavigate();

  return (
    <footer class="footer">
      <div id="w-node-_84c5db41-319e-2a7e-e8e5-9f81a3ce5b14-a3ce5b13">
        <div class="margin-bottom margin-medium">
          <div class="text-block">HOLA BLANCA</div>
        </div>

        <div class="margin-bottom margin-large">
          <div class="div-block-4">
            <a
              href="https://www.instagram.com/_hola_blanca_"
              target="_blank"
              class="footer-nav-link icon w-inline-block"
            >
              <div class="icon-embed-xsmall w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 56 55"
                  fill="none"
                  preserveAspectRatio="xMidYMid meet"
                  aria-hidden="true"
                  role="img"
                >
                  <path
                    d="M28.125 13.8242C35.7539 13.8242 42.0508 20.1211 42.0508 27.75C42.0508 35.5 35.7539 41.6758 28.125 41.6758C20.375 41.6758 14.1992 35.5 14.1992 27.75C14.1992 20.1211 20.375 13.8242 28.125 13.8242ZM28.125 36.832C33.0898 36.832 37.0859 32.8359 37.0859 27.75C37.0859 22.7852 33.0898 18.7891 28.125 18.7891C23.0391 18.7891 19.043 22.7852 19.043 27.75C19.043 32.8359 23.1602 36.832 28.125 36.832ZM45.8047 13.3398C45.8047 15.1562 44.3516 16.6094 42.5352 16.6094C40.7188 16.6094 39.2656 15.1562 39.2656 13.3398C39.2656 11.5234 40.7188 10.0703 42.5352 10.0703C44.3516 10.0703 45.8047 11.5234 45.8047 13.3398ZM55.0078 16.6094C55.25 21.0898 55.25 34.5312 55.0078 39.0117C54.7656 43.3711 53.7969 47.125 50.6484 50.3945C47.5 53.543 43.625 54.5117 39.2656 54.7539C34.7852 54.9961 21.3438 54.9961 16.8633 54.7539C12.5039 54.5117 8.75 53.543 5.48047 50.3945C2.33203 47.125 1.36328 43.3711 1.12109 39.0117C0.878906 34.5312 0.878906 21.0898 1.12109 16.6094C1.36328 12.25 2.33203 8.375 5.48047 5.22656C8.75 2.07812 12.5039 1.10938 16.8633 0.867188C21.3438 0.625 34.7852 0.625 39.2656 0.867188C43.625 1.10938 47.5 2.07812 50.6484 5.22656C53.7969 8.375 54.7656 12.25 55.0078 16.6094ZM49.1953 43.7344C50.6484 40.2227 50.2852 31.7461 50.2852 27.75C50.2852 23.875 50.6484 15.3984 49.1953 11.7656C48.2266 9.46484 46.4102 7.52734 44.1094 6.67969C40.4766 5.22656 32 5.58984 28.125 5.58984C24.1289 5.58984 15.6523 5.22656 12.1406 6.67969C9.71875 7.64844 7.90234 9.46484 6.93359 11.7656C5.48047 15.3984 5.84375 23.875 5.84375 27.75C5.84375 31.7461 5.48047 40.2227 6.93359 43.7344C7.90234 46.1562 9.71875 47.9727 12.1406 48.9414C15.6523 50.3945 24.1289 50.0312 28.125 50.0312C32 50.0312 40.4766 50.3945 44.1094 48.9414C46.4102 47.9727 48.3477 46.1562 49.1953 43.7344Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/HOLA-Blanca-285888555179089/"
              target="_blank"
              class="footer-nav-link icon w-inline-block"
            >
              <div class="icon-embed-xsmall w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 62 61"
                  fill="none"
                  preserveAspectRatio="xMidYMid meet"
                  aria-hidden="true"
                  role="img"
                >
                  <path
                    d="M61.0312 30.75C61.0312 45.7656 50.0117 58.2383 35.6016 60.418V39.4688H42.625L43.957 30.75H35.6016V25.1797C35.6016 22.7578 36.8125 20.457 40.5664 20.457H44.3203V13.0703C44.3203 13.0703 40.9297 12.4648 37.5391 12.4648C30.7578 12.4648 26.2773 16.7031 26.2773 24.2109V30.75H18.6484V39.4688H26.2773V60.418C11.8672 58.2383 0.96875 45.7656 0.96875 30.75C0.96875 14.1602 14.4102 0.71875 31 0.71875C47.5898 0.71875 61.0312 14.1602 61.0312 30.75Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </a>

            <a
              href="https://wa.me/212672920192"
              target="_blank"
              class="footer-nav-link icon center w-inline-block"
            >
              <div class="icon-embed-xsmall w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 55 55"
                  fill="none"
                  preserveAspectRatio="xMidYMid meet"
                  aria-hidden="true"
                  role="img"
                >
                  <path
                    d="M46.0156 8.61719C51.1016 13.7031 54.25 20.3633 54.25 27.6289C54.25 42.4023 41.8984 54.5117 27.0039 54.5117C22.5234 54.5117 18.1641 53.3008 14.168 51.2422L0 54.875L3.75391 40.9492C1.45312 36.9531 0.121094 32.3516 0.121094 27.5078C0.121094 12.7344 12.2305 0.625 27.0039 0.625C34.2695 0.625 41.0508 3.53125 46.0156 8.61719ZM27.0039 49.9102C39.3555 49.9102 49.6484 39.8594 49.6484 27.6289C49.6484 21.5742 47.1055 16.0039 42.8672 11.7656C38.6289 7.52734 33.0586 5.22656 27.125 5.22656C14.7734 5.22656 4.72266 15.2773 4.72266 27.5078C4.72266 31.7461 5.93359 35.8633 8.11328 39.4961L8.71875 40.3438L6.41797 48.5781L14.8945 46.2773L15.6211 46.7617C19.1328 48.8203 23.0078 49.9102 27.0039 49.9102ZM39.3555 33.1992C39.9609 33.5625 40.4453 33.6836 40.5664 34.0469C40.8086 34.2891 40.8086 35.6211 40.2031 37.1953C39.5977 38.7695 36.9336 40.2227 35.7227 40.3438C33.543 40.707 31.8477 40.5859 27.6094 38.6484C20.8281 35.7422 16.4688 28.9609 16.1055 28.5977C15.7422 28.1133 13.4414 24.9648 13.4414 21.5742C13.4414 18.3047 15.1367 16.7305 15.7422 16.0039C16.3477 15.2773 17.0742 15.1562 17.5586 15.1562C17.9219 15.1562 18.4062 15.1562 18.7695 15.1562C19.2539 15.1562 19.7383 15.0352 20.3438 16.3672C20.8281 17.6992 22.2812 20.9688 22.4023 21.332C22.5234 21.6953 22.6445 22.0586 22.4023 22.543C21.1914 25.0859 19.7383 24.9648 20.4648 26.1758C23.1289 30.6562 25.6719 32.2305 29.668 34.168C30.2734 34.5312 30.6367 34.4102 31.1211 34.0469C31.4844 33.5625 32.8164 31.9883 33.1797 31.3828C33.6641 30.6562 34.1484 30.7773 34.7539 31.0195C35.3594 31.2617 38.6289 32.8359 39.3555 33.1992Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
        <div class="margin-bottom margin-medium">
          <div class="text-size-small text-color-muted">
            <a
              href="https://holablanca.ma"
              target="_blank"
              class="text-style-link"
              style={{ textDecoration: "none" }}
            >
              HOLA BLANCA{" "}
            </a>
          </div>
          © 2025 Tous droits réservés.
        </div>
      </div>
      <div id="w-node-d1380d18-170b-dc5b-d0c9-134da1aa351f-a3ce5b13">
        <div class="margin-bottom margin-medium">
          <h3 class="heading-h6" style={{ fontWeight: "bold" }}>
            Adresse
          </h3>
        </div>
        <div class="footer-nav">
          <div class="footer-nav-link no-link">
            Ain Chock
            <br />
            61 Bd Tah, Casablanca
          </div>
          <a
            href="https://www.google.com/maps/place/HOLA+BLANCA+cafe+-+restaurant+-+glacier/data=!4m2!3m1!1s0x0:0x7c76ebc97b98427b?sa=X&ved=1t:2428&ictx=111"
            target="_blank"
            class="footer-nav-link"
          >
            Google maps
          </a>
        </div>
      </div>
      <div id="w-node-_84c5db41-319e-2a7e-e8e5-9f81a3ce5b17-a3ce5b13">
        <div class="margin-bottom margin-medium">
          <h3 class="heading-h6" style={{ fontWeight: "bold" }}>
            Pages
          </h3>
        </div>
        <div class="footer-nav">
          <a
            onClick={() => navigate("/menu")}
            style={{ cursor: "pointer" }}
            aria-current="page"
            class="footer-nav-link w--current"
          >
            Carte
          </a>
          <a
            onClick={() => navigate("/restaurant")}
            style={{ cursor: "pointer" }}
            class="footer-nav-link"
          >
            Restaurant
          </a>

          <a
            onClick={() => navigate("/events")}
            style={{ cursor: "pointer" }}
            class="footer-nav-link"
          >
            Anniversaires
          </a>
          <a
            onClick={() => navigate("/join")}
            style={{ cursor: "pointer" }}
            class="footer-nav-link"
          >
            Nous rejoindre
          </a>

          {/* <a href="/contact" class="footer-nav-link">
            Contact
          </a> */}
        </div>
      </div>
    </footer>
  );
};
