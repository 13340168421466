import React, { useState, useEffect, useRef, useContext } from "react";

import firebase from "../utils/FirebaseInfo.js";
import { ErrorPage } from "./ErrorPage";
import { useLocation, useNavigate } from "react-router-dom";
import { COLORS } from "../constants/theme";

import { promiseWithTimeout, loadData, customLoadData } from "../core/api";
import PuffLoader from "react-spinners/PuffLoader";
import { confirmAlert } from "react-confirm-alert"; // Import

import constants from "../constants/constants";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { CartContext } from "../core/CartProvider";
import { utils } from "../utils";

import "./styles.css";
import { Footer } from "./Footer.js";
import { FooterHelper } from "./FooterHelper.js";
import { Header } from "./Header.js";
import CartQuantityButton from "../components/cartQuantityButton/CartQuantityButton";

export const Menu = () => {
  const { getCurrentUser, removeAllItems } = useContext(CartContext);
  const location = useLocation();
  const navigate = useNavigate();
  const tabMenuRef = useRef(null); // Ref for the tab menu
  const tabRefs = useRef({});
  const [loading, setLoading] = useState(false);
  //BEGIN LOADING MENU

  const [menuRefetch, setMenuRefetch] = useState(false);
  const [status, setStatus] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setStatus(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [status]);
  useEffect(() => {
    setMenuRefetch(true);
    categ.refetchCategories();
    sousCateg.refetchSousCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const customLoadDataLocal = async (type, path, withDataOfKey, dataOfKey) => {
    if (type === "categorie") setCateg({ ...categ, isLoadingCategories: true });
    if (type === "config")
      setSousCateg({ ...sousCateg, isLoadingSousCategories: true });
    if (!status) {
      if (type === "categorie")
        setCateg({
          ...categ,
          isLoadingCategories: false,
          isRefetchingCategories: false,
          isErrorCategories: true,
        });
      if (type === "config")
        setSousCateg({
          ...sousCateg,
          isLoadingSousCategories: false,
          isRefetchingSousCategories: false,
          isErrorSousCategories: true,
        });
      setMenuRefetch(false);
      throw new Error("Connection error");
    }
    const res = await promiseWithTimeout(
      customFetch({ path, withDataOfKey, dataOfKey, type }),
      10000
    );
    return res;
  };

  const [sousCateg, setSousCateg] = useState({
    sousCategories: [],
    isLoadingSousCategories: false,
    isErrorSousCategories: false,
    isRefetchingSousCategories: false,
    refetchSousCategories: () =>
      customLoadDataLocal("config", "/CategorieConfig"),
  });
  const [categ, setCateg] = useState({
    categories: [],
    isLoadingCategories: false,
    isErrorCategories: false,
    isRefetchingCategories: false,
    refetchCategories: () => customLoadDataLocal("categorie", "/Categories"),
  });

  useEffect(() => {
    if (
      sousCateg.sousCategories &&
      sousCateg.sousCategories.length > 1 &&
      categ.categories &&
      categ.categories[0]?.key
    ) {
      setMenuRefetch(false);
    }
  }, [categ.categories, sousCateg.sousCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  const customFetch = ({ path, withDataOfKey, dataOfKey, type }) => {
    return firebase.ref(path).on(
      "value",
      async (snapshot) => {
        try {
          if (type === "categorie") {
            let data = [];
            snapshot.forEach((childsnap) => {
              if (withDataOfKey && dataOfKey) {
                const dataKey = childsnap.key;
                childsnap.forEach((subChildsnap) => {
                  let val = subChildsnap.val();
                  val[dataOfKey] = dataKey;
                  val["key"] = subChildsnap.key;
                  data.push(val);
                });
              } else {
                let val = childsnap.val();
                val["key"] = childsnap.key;
                data.push(val);
              }
            });
            setCateg({
              ...categ,
              categories: data,
              isLoadingCategories: false,
              isRefetchingCategories: false,
            });

            return data;
          }
          if (type === "config") {
            loadData("/CategorieConfig").then(async (categoriesConfig) => {
              let newSousCategories = [];
              let tempSousCategories = await customLoadData(
                "/SousCategories",
                true,
                "categorieKey"
              );

              tempSousCategories?.map((sousCategorie) => {
                if (sousCategorie?.customChoice) {
                  let newConfigArray = sousCategorie?.customChoice?.map(
                    (customChoice) => {
                      return categoriesConfig?.filter((catConfig) => {
                        return customChoice === Object.keys(catConfig)[0];
                      });
                    }
                  );
                  sousCategorie.customChoice = newConfigArray;
                }

                newSousCategories.push(sousCategorie);
              });

              setSousCateg({
                ...sousCateg,
                sousCategories: newSousCategories,
                isLoadingSousCategories: false,
                isRefetchingSousCategories: false,
              });
              return newSousCategories;
            });
          }

          const currentUser = getCurrentUser();
          // setDisplayForm(
          //   !(
          //     currentUser &&
          //     currentUser.phone &&
          //     currentUser.name &&
          //     currentUser.firstName &&
          //     currentUser.tableName
          //   ) &&
          //     location.pathname.split("/")[1] === constants.urlqrcode &&
          //     location.pathname.split("/")[3] === constants.suburlqrcode &&
          //     isNumeric(location.pathname.split("/")[2])
          // );
        } catch (error) {
          if (type === "categorie")
            setCateg({
              ...categ,
              isLoadingCategories: false,
              isRefetchingCategories: false,
              isErrorCategories: true,
            });
          if (type === "config")
            setSousCateg({
              ...sousCateg,
              isLoadingSousCategories: false,
              isRefetchingSousCategories: true,
              isErrorSousCategories: true,
            });
          setMenuRefetch(false);
        }
      },
      (error) => {
        if (type === "categorie")
          setCateg({
            ...categ,
            isLoadingCategories: false,
            isRefetchingCategories: false,
            isErrorCategories: true,
          });
        if (type === "config")
          setSousCateg({
            ...sousCateg,
            isLoadingSousCategories: false,
            isRefetchingSousCategories: true,
            isErrorSousCategories: true,
          });
        setMenuRefetch(false);
      }
    );
  };

  const groupProductsByCategoryName = (items, categories) => {
    return items.reduce((acc, item) => {
      const {
        categorieKey,
        name,
        price,
        image,
        customChoice,
        key,
        description,
      } = item;
      // Find the category name from array X
      const category = categories?.find((cat) => cat.key === categorieKey);

      const categoryName = category ? category.name : "N/A";
      if (category?.name) {
        // Check if the category already exists in the accumulator
        let existingCategory = acc.find(
          (cat) => cat.categoryName === categoryName
        );

        if (!existingCategory) {
          existingCategory = { categoryName, categorieKey, sousCategories: [] };
          acc.push(existingCategory);
        }

        // Add the item to the corresponding category
        existingCategory.sousCategories.push({
          name,
          price,
          image,
          customChoice,
          key,
          categorieKey,
          description,
        });
      }
      return acc;
    }, []);
  };
  //END LOADING MENU
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0, // Adjust for fixed header
      behavior: "smooth",
    });
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      let currentSection = activeTab;

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 100 && rect.bottom > 100) {
          currentSection = section.id;
        }
      });
      // if (currentSection !== activeTab) {
      setActiveTab(currentSection);
      scrollTabIntoView(currentSection);
      // }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleTabClick = (sectionId) => {
    setActiveTab(sectionId);
    scrollTabIntoView(sectionId);
    const section = document.getElementById(sectionId);

    if (section) {
      const pageLeft = document.getElementById("left-section");
      // Get the height of `page-left`
      const pageLeftHeight = pageLeft ? pageLeft.clientHeight : 0;
      // Determine `top` based on screen size
      const topValue =
        window.innerWidth <= 991
          ? section.offsetTop + pageLeftHeight
          : section.offsetTop;
      window.scrollTo({
        top: topValue, // Adjust for fixed header
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollTabIntoView = (tabId) => {
    if (!tabMenuRef.current || !tabRefs.current[tabId]) return;

    const container = tabMenuRef.current;
    const tab = tabRefs.current[tabId];

    const containerRect = container.getBoundingClientRect();
    const tabRect = tab.getBoundingClientRect();

    if (
      tabRect.left < containerRect.left ||
      tabRect.right > containerRect.right
    ) {
      container.scrollTo({
        left: tab.offsetLeft - container.offsetWidth / 2 + tab.offsetWidth / 2,
        behavior: "smooth",
      });
    }
  };

  return (
    <div class="page-wrapper">
      <main class="main-wrapper">
        <div class="page-left" id="left-section">
          <Header />
          <div class="page-left-content">
            <div class="page-padding">
              <div class="container-small">
                <div
                  data-w-id="44e0cbec-0660-b581-0bc1-63f15cd64b14"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  class="heading-h1-italic"
                >
                  EXPLORER
                </div>
                <h1
                  data-w-id="12604c35-9ec1-dd71-69e4-918b5f69cb5b"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  class="heading-medium"
                >
                  NOTRE CARTE
                </h1>
              </div>
            </div>
          </div>
          <div class="overlay z5"></div>
          <img
            src="https://cdn.prod.website-files.com/665a07d2af3ce526f2f9a949/665a0eabbf2004ccf3d23c23_202402-Skinfix-MaisonRowena-29.webp"
            loading="eager"
            sizes="(max-width: 991px) 100vw, 50vw"
            srcset="
          https://cdn.prod.website-files.com/665a07d2af3ce526f2f9a949/665a0eabbf2004ccf3d23c23_202402-Skinfix-MaisonRowena-29-p-500.webp   500w,
          https://cdn.prod.website-files.com/665a07d2af3ce526f2f9a949/665a0eabbf2004ccf3d23c23_202402-Skinfix-MaisonRowena-29-p-800.webp   800w,
          https://cdn.prod.website-files.com/665a07d2af3ce526f2f9a949/665a0eabbf2004ccf3d23c23_202402-Skinfix-MaisonRowena-29-p-1080.webp 1080w,
          https://cdn.prod.website-files.com/665a07d2af3ce526f2f9a949/665a0eabbf2004ccf3d23c23_202402-Skinfix-MaisonRowena-29-p-1600.webp 1600w,
          https://cdn.prod.website-files.com/665a07d2af3ce526f2f9a949/665a0eabbf2004ccf3d23c23_202402-Skinfix-MaisonRowena-29-p-2000.webp 2000w,
          https://cdn.prod.website-files.com/665a07d2af3ce526f2f9a949/665a0eabbf2004ccf3d23c23_202402-Skinfix-MaisonRowena-29.webp        2500w
        "
            alt=""
            class="page-left-image"
          />
          {/* FOOTER */}
          {!utils.isOrderMode(location.pathname) && <Footer />}
        </div>
        <div class="page-right">
          {menuRefetch ||
          categ.isLoadingCategories ||
          sousCateg.isLoadingSousCategories ? (
            <div
              style={{
                height: "800px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                marginTop: "5vh",
              }}
            >
              <PuffLoader size={100} color="white" />
            </div>
          ) : sousCateg.isErrorSousCategories || categ.isErrorCategories ? (
            <ErrorPage />
          ) : (
            <>
              <div class="scroll-hint">👆</div>

              <div
                class="menu-nav"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  overflowX: "auto",
                  scrollbarWidth: "thin",
                  whiteSpace: "nowrap",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  scrollbarWidth: "none" /* For Firefox */,
                  msOverflowStyle: "none" /* For IE and Edge */,
                  borderBottom: "0.5px solid #8080806b",
                }}
                ref={tabMenuRef}
              >
                {categ.categories
                  ?.filter((item) => item.name !== "Promotions")
                  .map((tab) => (
                    <a
                      href={tab.key}
                      data-id={tab.key}
                      ref={(el) => (tabRefs.current[tab.key] = el)}
                      onClick={(e) => {
                        e.preventDefault();
                        handleTabClick(tab.key);
                      }}
                      class={`menu-nav-link ${
                        activeTab === tab.key ? "w--current" : ""
                      }`}
                    >
                      {tab.name}
                    </a>
                  ))}
              </div>
              <div class="page-right-inner" style={{ padding: "3rem" }}>
                {!utils.isOrderMode(location.pathname) && (
                  <div class="margin-bottom margin-xsmall">
                    <div class="text-size-medium text-color-muted">
                      Soyez surpris par notre vaste menu rempli de délicieuses
                      spécialités. Chez HOLA BLANCA, que vous veniez pour un
                      repas convivial ou un boisson frais, il y en a pour tous
                      les goûts. <br />
                      <br />
                    </div>
                  </div>
                )}

                <div class="container-small">
                  {groupProductsByCategoryName(
                    sousCateg.sousCategories,
                    categ.categories
                  )
                    ?.filter((item) => item.categoryName !== "Promotions")
                    ?.map((sousCategorie, index) => {
                      return (
                        <div
                          id={sousCategorie.categorieKey}
                          class={`section padding-top ${
                            index > 0 && "padding-xhuge"
                          }`}
                        >
                          <div class="margin-bottom margin-large">
                            <div class="w-dyn-list">
                              <div role="list" class="w-dyn-items">
                                <div role="listitem" class="w-dyn-item">
                                  <div
                                    data-w-id="e261a45e-bc05-bb70-a6df-96ec5f48975a"
                                    style={{
                                      transform:
                                        "translate3d(0px, 10px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                      opacity: 1,
                                      transformStyle: "preserve-3d",
                                    }}
                                    class="heading-h2-italic"
                                  >
                                    {sousCategorie.categoryName}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="w-dyn-list">
                            <div role="list" class="menu w-dyn-items">
                              {sousCategorie?.sousCategories?.map(
                                (sousCateg) => (
                                  <div
                                    role="listitem"
                                    class="w-dyn-item"
                                    onClick={async () => {
                                      if (
                                        utils.isOrderMode(location.pathname)
                                      ) {
                                        const start = 7 * 60;
                                        const end = 24 * 60;

                                        const endDej = 13 * 60;
                                        setLoading(true);
                                        const date =
                                          await utils.fetchCurrentTime();
                                        setLoading(false);
                                        const now =
                                          date.getHours() * 60 +
                                          date.getMinutes();
                                        if (
                                          utils.isOrderMode(
                                            location.pathname
                                          ) &&
                                          (start > now || now > end)
                                        ) {
                                          confirmAlert({
                                            customUI: ({ onClose }) => {
                                              return (
                                                <div
                                                  className="custom-ui"
                                                  style={{
                                                    padding: "20px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <h1>HOLA 🤗</h1>
                                                  <p>
                                                    Désolé ! Votre commande ne
                                                    peut être traité en dehors
                                                    des horaires de livraison{" "}
                                                  </p>

                                                  <button
                                                    onClick={() => {
                                                      onClose();
                                                    }}
                                                    style={{
                                                      margin: 5,
                                                      border: "none",
                                                      padding: 15,
                                                    }}
                                                  >
                                                    J'ai compris !
                                                  </button>
                                                </div>
                                              );
                                            },
                                            closeOnClickOutside: false,
                                          });
                                        } else {
                                          if (
                                            sousCateg.categorieKey ===
                                            "CategorieUID94"
                                          ) {
                                            if (start > now || now > endDej)
                                              //TO CHECK
                                              confirmAlert({
                                                customUI: ({ onClose }) => {
                                                  return (
                                                    <div
                                                      className="custom-ui"
                                                      style={{
                                                        padding: "20px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <h1
                                                        style={{
                                                          color: COLORS.black,
                                                        }}
                                                      >
                                                        HOLA 🤗
                                                      </h1>
                                                      <p
                                                        style={{
                                                          color: COLORS.black,
                                                        }}
                                                      >
                                                        Nos petits déjeuners ne
                                                        sont servis que dans la
                                                        matinée
                                                      </p>

                                                      <button
                                                        onClick={() => {
                                                          onClose();
                                                        }}
                                                        style={{
                                                          margin: 5,
                                                          border: "none",
                                                          padding: 15,
                                                          backgroundColor:
                                                            "black",
                                                          color: "#f7da72",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        J'ai compris !
                                                      </button>
                                                    </div>
                                                  );
                                                },
                                                closeOnClickOutside: false,
                                              });
                                            else {
                                              utils.isOrderMode(
                                                location.pathname
                                              ) &&
                                                navigate(
                                                  "/" +
                                                    constants.urlqrcode +
                                                    "/" +
                                                    utils.getTableNumber(
                                                      location.pathname
                                                    ) +
                                                    "/foodDetail/" +
                                                    constants.suburlqrcode,
                                                  { state: sousCateg }
                                                );
                                            }
                                          } else {
                                            utils.isOrderMode(
                                              location.pathname
                                            ) &&
                                              navigate(
                                                "/" +
                                                  constants.urlqrcode +
                                                  "/" +
                                                  utils.getTableNumber(
                                                    location.pathname
                                                  ) +
                                                  "/foodDetail/" +
                                                  constants.suburlqrcode,
                                                { state: sousCateg }
                                              );
                                          }
                                        }
                                      }
                                    }}
                                  >
                                    <div
                                      class="menu-item"
                                      style={{
                                        transform:
                                          "translate3d(0px, 10px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                        transformStyle: "preserve-3d",
                                        opacity: 1,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        class="menu-content"
                                        style={{
                                          flexDirection:
                                            window.innerWidth <= 550
                                              ? "column"
                                              : "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        {/* {sousCateg.image && (
                                          <img
                                            src={sousCateg.image}
                                          />
                                        )} */}
                                        {sousCateg.image ? (
                                          <img
                                            src={sousCateg.image}
                                            alt={sousCateg.name}
                                            style={{
                                              width:
                                                window.innerWidth > 550
                                                  ? "25vh"
                                                  : undefined,
                                              // height:
                                              //   window.innerWidth <= 991
                                              //     ? "9vh"
                                              //     : "12vh",
                                              borderRadius:
                                                window.innerWidth <= 550
                                                  ? "5%"
                                                  : "20%", // Makes the image circular
                                              objectFit:
                                                window.innerWidth <= 550
                                                  ? "cover"
                                                  : "contain", // Ensures the image fills the space without distortion
                                              outline: "2px solid black",
                                              outlineOffset: "-0.5px",
                                              maxHeight: "20vh",
                                              // border: "0.5px solid #f7da71", // Optional: adds a border like before
                                            }}
                                          />
                                        ) : (
                                          <div
                                            style={{
                                              width:
                                                window.innerWidth <= 550
                                                  ? "100%"
                                                  : "12vh",
                                              height:
                                                window.innerWidth <= 550
                                                  ? "9vh"
                                                  : "12vh",
                                              backgroundColor: "black",
                                              border:
                                                window.innerWidth <= 550
                                                  ? "0.5px solid #ffffff82"
                                                  : "2px solid white",
                                              borderRadius:
                                                window.innerWidth <= 550
                                                  ? "3%"
                                                  : "50%" /* Makes it a circle */,
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              aspectRatio: "1/1",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <h4
                                              style={{
                                                textAlign: "center",
                                                fontSize: "12px",
                                                fontWeight: 300,
                                                padding: "10px",
                                              }}
                                            >
                                              {sousCateg.name}
                                            </h4>
                                          </div>
                                        )}
                                        <div style={{ width: "100%" }}>
                                          <div class="menu-content-top">
                                            <div class="menu-title-wrapper">
                                              <h3
                                                class="menu-title"
                                                style={{ fontSize: "0.8rem" }}
                                              >
                                                {sousCateg.name}
                                              </h3>
                                            </div>
                                            <div class="div-block-6"></div>
                                            <div class="menu-price-wrapper">
                                              <div class="menu-price text-color-muted"></div>
                                              <div
                                                class="menu-price"
                                                style={{ fontSize: "0.8rem" }}
                                              >
                                                {sousCateg.price}
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div class="menu-content-top">
                                      <div class="menu-title-wrapper">
                                        <h3 class="small">some text</h3>
                                      </div>
                                      <div class="div-block-6-copy"></div>
                                      <div class="menu-price-wrapper">
                                        <div class="menu-price text-color-muted"></div>
                                        <div class="menu-price small w-dyn-bind-empty"></div>
                                      </div>
                                    </div> */}
                                          {sousCateg.description && (
                                            <div class="menu-content-bottom">
                                              <div
                                                class="menu-subtitle text-color-muted"
                                                style={{ fontSize: "15px" }}
                                              >
                                                {sousCateg.description}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {!utils.isOrderMode(location.pathname) && <FooterHelper />}
                  <div
                    style={{
                      position: "fixed",
                      bottom: "50px",
                      right: "10px",
                      zIndex: 9999,
                    }}
                  >
                    {utils.isOrderMode(location.pathname) &&
                      !location?.pathname
                        ?.toLocaleLowerCase()
                        .includes("mycart") &&
                      utils.isOrderMode(location.pathname) && (
                        <div
                          style={{
                            position: "absolute",
                            right: "3%",
                            width: "auto",
                          }}
                        >
                          <CartQuantityButton
                            onPress={() => {
                              navigate(
                                "/" +
                                  constants.urlqrcode +
                                  "/" +
                                  utils.getTableNumber(location.pathname) +
                                  "/MyCart/" +
                                  constants.suburlqrcode
                              );
                            }}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </main>
      {/* Backdrop with Smooth Loader */}
      <div className={`backdrop ${loading ? "show" : ""}`}>
        <div className="loader">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
