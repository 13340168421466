import React, { useContext, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CartContext } from "./core/CartProvider";
import MyCart from "./templates/mycart";
import constants from "./constants/constants";

import FoodDetail from "./templates/foodDetail.js";
import App from "./pages/homepage.js";
import { useIdleTimer } from "react-idle-timer";
import { Menu } from "./pages/Menu";
import { Restaurant } from "./pages/Restaurant";
import { Events } from "./pages/Events";
import { WorkingAt } from "./pages/WorkingAt";
import utils from "./utils/utils.js";

function MainApp() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getCurrentUser, removeAllItems } = useContext(CartContext);

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    if (utils.isOrderMode(location.pathname)) {
      removeAllItems();
      navigate("/");
    }
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  };

  useEffect(() => {
    if (location.pathname === "/") removeAllItems();
  }, [location]);

  const idleTimer = useIdleTimer({ onIdle, onActive, timeout: 90000 });

  return (
    <div>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/menu" element={<Menu />} />
        <Route exact path="/restaurant" element={<Restaurant />} />
        <Route exact path="/events" element={<Events />} />
        <Route exact path="/join" element={<WorkingAt />} />

        <Route
          path={
            constants.urlqrcode + "/:tablename/MyCart/" + constants.suburlqrcode
          }
          element={<MyCart />}
        />
        <Route
          path={
            constants.urlqrcode +
            "/:tablename/FoodDetail/" +
            constants.suburlqrcode
          }
          element={<FoodDetail />}
        />

        <Route
          path={constants.urlqrcode + "/:tablename/" + constants.suburlqrcode}
          element={<Menu />}
        >
          <Route path="menu" element={<Menu />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default MainApp;
