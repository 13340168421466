import React from "react";
import { COLORS, FONTS, SIZES } from "../../constants/theme";

const StepperInput = ({
  containerStyle,
  onMinus,
  onPlus,
  value,
  direction = "horizontal",
}) => {
  const isVertical = direction === "vertical";

  // Wrapper Styles
  const containerStyles = {
    display: "flex",
    flexDirection: isVertical ? "column" : "row",
    height: isVertical ? SIZES.height * 0.3 : 60,
    // width: isVertical ? 60 : SIZES.width * 0.3,
    backgroundColor: COLORS.black,
    borderRadius: 30,
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    padding: 5,
    border: "2px solid #f7da72",
    gap: isVertical ? 8 : 12, // Spacing between elements
    ...containerStyle,
  };

  // Button Styles
  const buttonStyles = {
    padding: 5,
    cursor: "pointer",
    borderRadius: "50%",
    border: "0.5px solid rgba(0, 0, 0, 0.15)",
  };

  return (
    <div style={containerStyles}>
      {/* Minus Button */}
      {onMinus && (
        <div
          onClick={onMinus}
          style={{
            ...buttonStyles,
            backgroundColor: value > 1 ? "#f7da72" : COLORS.gray,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="25"
            height="25"
            style={{ fill: value > 1 ? COLORS.black : COLORS.lightGray }}
          >
            <path d="M19 13H5V11H19V13Z" />
          </svg>
        </div>
      )}

      {/* Value Display */}
      <p
        style={{
          ...FONTS.h3,
          lineHeight: 0,
          color: COLORS.white,
          fontWeight: "bold",
          marginTop: 20,
        }}
      >
        {value}
      </p>

      {/* Plus Button */}
      {onPlus && (
        <div
          onClick={onPlus}
          style={{
            ...buttonStyles,
            backgroundColor: "#f7da72",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="25"
            height="25"
            style={{ fill: COLORS.black }}
          >
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default StepperInput;
