import constants from "../constants/constants";

function formatDate(d) {
  const mounths = [
    "Janv.",
    "Fév.",
    "Mars",
    "Avr.",
    "Mai",
    "Juin",
    "Juil.",
    "Août",
    "Sept.",
    "Oct.",
    "Nov.",
    "Déc.",
  ];
  const days = ["Dim.", "Lun.", "Mar.", "Merc.", "Jeu.", "Ven.", "Sam."];
  return (
    days[d.getDay()] +
    " " +
    d.getDate() +
    " " +
    mounths[d.getMonth()] +
    " " +
    d.getFullYear() +
    " [ " +
    d.getHours() +
    ":" +
    d.getMinutes() +
    " ]"
  );
}

const fetchCurrentTime = async () => {
  try {
    // let response = await fetch(
    //   "https://timeapi.io/api/time/current/zone?timeZone=Africa%2FCasablanca"
    // );
    // let json = await response.json();
    //let d = new Date(JSON.parse("2022-06-03T17:26:57.606Z"))
    let d = new Date(new Date().toISOString());
    //console.log("ddddddddd ",d)
    //console.log("new Date().getTimezoneOffset() ",new Date().getTimezoneOffset())
    //d.setTime( d.getTime() - new Date().getTimezoneOffset()*60*1000 );
    //console.log('json.currentDateTimeyyy; ', json.datetime, d);
    return d;
  } catch (error) {
    console.error("error fetchCurrentTime ", error);
    return new Date();
  }
};

const displayExtrasItem = (categorieConf) => {
  let extrasItem = [];

  let countExtra = 0;
  categorieConf?.map((catConfig, index) => {
    catConfig?.value?.map((catConf, currIndex) => {
      countExtra++;
    });
  });

  categorieConf?.map((catConfig, index) => {
    return catConfig?.value?.map((catConf, currIndex) => {
      extrasItem.push(
        extrasItem.length + 1 === countExtra ? catConf : catConf + ", "
      );
    });
  });
  return extrasItem;
};

const typeOfDelivery = (deliveryInfo) => {
  if (deliveryInfo?.deliveryType === "domicile") return "| À domicile";
  if (deliveryInfo?.deliveryType === "selfdelivery") return "| Self service";
  if (deliveryInfo?.deliveryType === "surplace")
    return "| À table N° " + deliveryInfo.tableNumber;
};

function validatePhoneNumber(value, setUsernameError) {
  var pattern = /^\d+$/;
  if (
    value.length != 10 ||
    !pattern.test(value) ||
    (value.substring(0, 2) !== "06" && value.substring(0, 2) !== "07")
  ) {
    setUsernameError("Numéro de téléphone invalide. Ex: 0611223344");
  } else {
    setUsernameError("");
  }
}

function validateUsername(value, setUsernameError) {
  if (value.length < 3) {
    setUsernameError("Un minimum de 3 caractères est requis");
  } else {
    setUsernameError("");
  }
}

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

function isOrderMode(url) {
  const splittedUrl = url?.split("/");
  return (
    splittedUrl?.[1] === constants.urlqrcode &&
    isNumeric(splittedUrl?.[2]) &&
    splittedUrl?.[2] > 0 &&
    (splittedUrl?.[3] === constants.suburlqrcode ||
      splittedUrl?.[4] === constants.suburlqrcode)
  );
}

function getTableNumber(url) {
  const splittedUrl = url?.split("/");
  return splittedUrl?.[2];
}

const utils = {
  formatDate,
  fetchCurrentTime,
  displayExtrasItem,
  typeOfDelivery,
  validatePhoneNumber,
  validateUsername,
  isOrderMode,
  getTableNumber,
};

export default utils;
