import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import logo1 from "../images/20250324_003632.jpg";
import logo2 from "../images/20250324_003646.jpg";
import logo3 from "../images/20250324_003701.jpg";
import logo4 from "../images/20250324_003718.jpg";
import logo5 from "../images/20250324_003741.jpg";
import logo6 from "../images/20250324_003757.jpg";

import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper/modules";

const images = [logo1, logo2, logo3, logo4, logo5, logo6];

export default function App() {
  return (
    <>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      >
        {images?.map((image) => (
          <SwiperSlide>
            <img src={image} style={{ height: "inherit" }} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
