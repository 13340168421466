import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/layout";
import { useLocation } from "react-router-dom";
import firebase from "../utils/FirebaseInfo.js";
import { ErrorPage } from "./ErrorPage";
import { Categories } from "./Categories";
import { Home } from "./Home";

import { promiseWithTimeout, loadData, customLoadData } from "../core/api";
import PuffLoader from "react-spinners/PuffLoader";
import constants from "../constants/constants";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { CartContext } from "../core/CartProvider";
import { utils } from "../utils";
import { Menu } from "./Menu";

const IndexPage = (data) => {
  // const [menuList, setMenuList] = useState([]);
  const { getCurrentUser, removeAllItems } = useContext(CartContext);

  // const navigate = useNavigate();
  // const handleChangeCategory = (selectedCategory, path) => {
  //   /*setMenuList(
  //     sousCateg.sousCategories?.filter(
  //       (subCateg) => subCateg.categorieKey === selectedCategory,
  //     ),
  //   );*/
  //   const currentUser = getCurrentUser();
  //   if (location.pathname === "/") {
  //     removeAllItems();
  //   }
  //   navigate(
  //     currentUser && currentUser.tableName ? location.pathname + path : path,
  //     {
  //       state: sousCateg.sousCategories?.filter(
  //         (subCateg) => subCateg.categorieKey === selectedCategory
  //       ),
  //     }
  //   );
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const [status, setStatus] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setStatus(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [status]);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);

  const { updateCurrentUser } = useContext(CartContext);
  const [displayForm, setDisplayForm] = useState(false);

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (
      !(
        currentUser &&
        currentUser.phone &&
        currentUser.name &&
        currentUser.firstName &&
        currentUser.tableName
      )
    ) {
      setDisplayForm(false);
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        {
          !status ? (
            <ErrorPage subtitle="Merci de vérifier votre accès à internet" />
          ) : displayForm ? (
            <div
              className="custom-ui"
              style={{ padding: "20px", textAlign: "center" }}
            >
              <h1>HOLA 🤗</h1>
              <h3>
                Vous êtes
                {location.pathname.split("/")[2] === "0" ? (
                  " au comptoir"
                ) : (
                  <>
                    {" "}
                    sur la table{" "}
                    <span
                      style={{
                        lineHeight: 1.5,
                        backgroundColor: "rgb(208, 60, 57)",
                        borderRadius: 20,
                        padding: 10,
                        color: "white",
                      }}
                    >
                      {location.pathname.split("/")[2]}
                    </span>
                  </>
                )}
              </h3>
              <div>
                <div style={{ marginBottom: 20 }}>
                  <label>
                    <p style={{ marginBottom: 5 }}>Numéro de téléphone</p>
                    <input
                      placeholder="0xxxxxxxxx ..."
                      style={{
                        borderWidth: 1,
                        padding: 8,
                        borderRadius: 10,
                        borderColor: "#80808045",
                      }}
                      type="number"
                      value={phone}
                      name="phone"
                      onChange={(e) => {
                        utils.validatePhoneNumber(
                          e.target.value,
                          setPhoneError
                        );
                        setPhone(e.target.value);
                      }}
                    />
                    {phoneError && (
                      <p
                        style={{
                          fontSize: "small",
                          marginTop: "5px",
                          color: "#d03c39",
                          marginBottom: 5,
                        }}
                      >
                        {phoneError}
                      </p>
                    )}
                  </label>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <label>
                    <p style={{ marginBottom: 5 }}>Nom complet</p>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ marginBottom: 15 }}>
                        <input
                          placeholder="Nom ..."
                          style={{
                            borderWidth: 1,
                            padding: 8,
                            borderRadius: 10,
                            borderColor: "#80808045",
                          }}
                          type="text"
                          value={name}
                          name="name"
                          onChange={(e) => {
                            utils.validateUsername(
                              e.target.value,
                              setNameError
                            );
                            setName(e.target.value);
                          }}
                        />
                        {nameError && (
                          <p
                            style={{
                              fontSize: "small",
                              marginTop: "5px",
                              color: "#d03c39",
                              marginBottom: 5,
                            }}
                          >
                            {nameError}
                          </p>
                        )}
                      </div>

                      <div>
                        <input
                          placeholder="Prénom ..."
                          style={{
                            borderWidth: 1,
                            padding: 8,
                            borderRadius: 10,
                            borderColor: "#80808045",
                          }}
                          type="text"
                          value={firstName}
                          name="firstName"
                          onChange={(e) => {
                            utils.validateUsername(
                              e.target.value,
                              setFirstNameError
                            );
                            setFirstName(e.target.value);
                          }}
                        />
                        {firstNameError && (
                          <p
                            style={{
                              fontSize: "small",
                              marginTop: "5px",
                              color: "#d03c39",
                              marginBottom: 5,
                            }}
                          >
                            {firstNameError}
                          </p>
                        )}
                      </div>
                    </div>
                  </label>
                </div>

                <button
                  disabled={
                    phone.length <= 0 ||
                    name.length <= 0 ||
                    firstName.length <= 0 ||
                    phoneError ||
                    nameError ||
                    firstNameError
                  }
                  onClick={() => {
                    updateCurrentUser({
                      phone,
                      name,
                      firstName,
                      tableName: location.pathname.split("/")[2],
                    });

                    setDisplayForm(false);
                  }}
                  style={{
                    width: "80%",
                    margin: 5,
                    borderRadius: 10,
                    border: "none",
                    padding: 15,
                  }}
                >
                  Continuer
                </button>
              </div>
            </div>
          ) : (
            // <Menu data={categ.categories} />
            <Home />
          )
          // <Categories
          //   data={categ.categories}
          //   handleChangeCategory={handleChangeCategory}
          // />
        }
      </div>
    </>
  );
};

export default IndexPage;
