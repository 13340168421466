import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaFacebook, FaInstagram, FaWhatsapp, FaPlus } from "react-icons/fa";

export const Footer = ({ isFixed }) => {
  const navigate = useNavigate();

  //BEGIN MENU DRAWER
  const [isOpenTime, setIsOpenTime] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdownTime = () => {
    setIsOpenTime((prev) => !prev);
  };

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const dropdownRefMenu = useRef(null);
  const toggleDropdownMenu = () => {
    setIsOpenMenu((prev) => !prev);
  };

  const handleMouseEnterTime = () => {
    setIsOpenTime(true);
    setIsOpenMenu(false);
  };

  const handleMouseLeaveTime = (event) => {
    // Check if the mouse is leaving both the button and the dropdown
    // if (
    //   dropdownRef.current &&
    //   !dropdownRef.current?.contains(event.relatedTarget)
    // ) {
    //   setIsOpenTime(false);
    // }
  };

  const handleMouseEnterMenu = () => {
    setIsOpenMenu(true);
    setIsOpenTime(false);
  };

  const handleMouseLeaveMenu = (event) => {
    // Check if the mouse is leaving both the button and the dropdown
    // if (
    //   dropdownRefMenu.current &&
    //   !dropdownRefMenu.current?.contains(event.relatedTarget)
    // ) {
    //   setIsOpenMenu(false);
    // }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenTime(false);
    }
    if (
      dropdownRefMenu.current &&
      !dropdownRefMenu.current.contains(event.target)
    ) {
      setIsOpenMenu(false);
    }
  };

  useEffect(() => {
    if (isOpenTime || isOpenMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenTime, isOpenMenu]);
  //END MENU DRAWER
  return (
    <div class={`navbar-wrapper ${isFixed ? "fixed" : "absolute"}`}>
      <div class="navbar-inner">
        <nav class="navbar" style={{ padding: "5px" }}>
          <div
            data-w-id="db97d287-b446-147e-53c1-a44829110273"
            class="navbar-dropdown-wrapper"
            ref={dropdownRefMenu}
          >
            <div
              class="navbar-dropdown-trigger"
              onClick={toggleDropdownMenu}
              // onMouseEnter={handleMouseEnterMenu}
              onMouseLeave={handleMouseLeaveMenu}
            >
              <div class="icon-1x1-default w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"></path>
                </svg>
              </div>
            </div>
            <div
              class="navbar-dropdown menu"
              // onMouseEnter={handleMouseEnterMenu}
              onMouseLeave={handleMouseLeaveMenu}
              style={{
                opacity: isOpenMenu ? 1 : 0,
                display: isOpenMenu ? "block" : "none",
                transform:
                  "translate3d(0px, 10px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
            >
              {/* <div class="margin-bottom margin-small">
                <h3 class="heading-h5">Alle pagina's</h3>
              </div> */}
              <div class="mobile-menu-nav">
                <a href="/" class="mobile-menu-nav-link w-inline-block">
                  <div class="mobile-menu-nav-text">Accueil</div>
                  <div class="clip">
                    <div
                      class="mobile-menu-nav-icon w-embed"
                      style={{
                        transform:
                          "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                      </svg>
                    </div>
                  </div>
                </a>
                <div class="mobile-menu-nav-line"></div>
                <a
                  onClick={() => navigate("/menu")}
                  style={{ cursor: "pointer" }}
                  aria-current="page"
                  class="mobile-menu-nav-link w-inline-block w--current"
                >
                  <div class="mobile-menu-nav-text">Carte</div>
                  <div class="clip">
                    <div
                      class="mobile-menu-nav-icon w-embed"
                      style={{
                        transform:
                          "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                      </svg>
                    </div>
                  </div>
                </a>
                <div class="mobile-menu-nav-line"></div>
                <a
                  onClick={() => navigate("/restaurant")}
                  style={{ cursor: "pointer" }}
                  class="mobile-menu-nav-link w-inline-block"
                >
                  <div class="mobile-menu-nav-text">Restaurant</div>
                  <div class="clip">
                    <div
                      class="mobile-menu-nav-icon w-embed"
                      style={{
                        transform:
                          "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                      </svg>
                    </div>
                  </div>
                </a>
                <div class="mobile-menu-nav-line"></div>

                <a
                  onClick={() => navigate("/events")}
                  style={{ cursor: "pointer", alignItems: "normal" }}
                  class="mobile-menu-nav-link w-inline-block"
                >
                  <div class="div-block-8">
                    <div class="mobile-menu-nav-text highlight">
                      Anniversaires
                    </div>
                  </div>
                  <div class="clip">
                    <div
                      class="mobile-menu-nav-icon w-embed"
                      style={{
                        transform:
                          "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                      </svg>
                    </div>
                  </div>
                </a>
                <div class="mobile-menu-nav-line"></div>
                <a
                  aria-disabled="true"
                  onClick={() => navigate("/join")}
                  style={{ cursor: "pointer", alignItems: "normal" }}
                  class="mobile-menu-nav-link w-inline-block"
                >
                  <div class="mobile-menu-nav-text">Nous rejoindre</div>
                  <div class="clip">
                    <div
                      class="mobile-menu-nav-icon w-embed"
                      style={{
                        transform:
                          "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                      </svg>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div
            data-w-id="f9698d3c-d6f0-6743-a356-7e1812370ada"
            class="navbar-dropdown-wrapper"
            ref={dropdownRef}
          >
            <div
              class="navbar-dropdown-trigger"
              onClick={toggleDropdownTime}
              // onMouseEnter={handleMouseEnterTime}
              onMouseLeave={handleMouseLeaveTime}
            >
              <div class="icon-embed-xsmall w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 62 63"
                  fill="none"
                  preserveAspectRatio="xMidYMid meet"
                  aria-hidden="true"
                  role="img"
                >
                  <path
                    d="M56.1875 31.75C56.1875 22.7891 51.3438 14.5547 43.5938 9.95312C35.7227 5.47266 26.1562 5.47266 18.4062 9.95312C10.5352 14.5547 5.8125 22.7891 5.8125 31.75C5.8125 40.832 10.5352 49.0664 18.4062 53.668C26.1562 58.1484 35.7227 58.1484 43.5938 53.668C51.3438 49.0664 56.1875 40.832 56.1875 31.75ZM0 31.75C0 20.7305 5.8125 10.5586 15.5 4.98828C25.0664 -0.582031 36.8125 -0.582031 46.5 4.98828C56.0664 10.5586 62 20.7305 62 31.75C62 42.8906 56.0664 53.0625 46.5 58.6328C36.8125 64.2031 25.0664 64.2031 15.5 58.6328C5.8125 53.0625 0 42.8906 0 31.75ZM28.0938 15.2812C28.0938 13.707 29.3047 12.375 31 12.375C32.5742 12.375 33.9062 13.707 33.9062 15.2812V30.2969L44.1992 37.0781C45.5312 38.0469 45.8945 39.8633 45.0469 41.1953C44.0781 42.5273 42.2617 42.8906 40.9297 41.9219L29.3047 34.1719C28.5781 33.6875 28.0938 32.7188 28.0938 31.75V15.2812Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </div>
            <div
              class="navbar-dropdown opening"
              // onMouseEnter={handleMouseEnterTime}
              onMouseLeave={handleMouseLeaveTime}
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
                display: isOpenTime ? "block" : "none",
                opacity: isOpenTime ? 1 : 0,
              }}
            >
              <div class="margin-bottom margin-small">
                <h3 class="heading-h5">Horaires d'ouverture</h3>
              </div>
              <div class="margin-bottom margin-xsmall">
                <div class="opening-hours-row">
                  <div>
                    <strong>Lu - Di</strong>
                  </div>
                  <div>07:00 - 00:30</div>
                </div>
              </div>
              <div class="margin-bottom margin-small">
                <h3 class="heading-h5">Nous contacter</h3>
              </div>
              <div class="margin-bottom margin-xsmall">
                <div class="opening-hours-row" style={{ cursor: "pointer" }}>
                  <div>
                    <strong>
                      <a
                        href="https://www.instagram.com/_hola_blanca_"
                        target="_blank"
                      >
                        <FaInstagram color="black" size={25} />
                      </a>
                    </strong>
                  </div>
                  <div>Instagram</div>
                  <div class="clip">
                    <div
                      class="mobile-menu-nav-icon w-embed"
                      style={{
                        transform:
                          "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="margin-bottom margin-xsmall">
                <div class="opening-hours-row" style={{ cursor: "pointer" }}>
                  <div>
                    <strong>
                      <a
                        href="https://www.facebook.com/HOLA-Blanca-285888555179089/"
                        target="_blank"
                      >
                        <FaFacebook color="black" size={25} />
                      </a>
                    </strong>
                  </div>
                  <div>Facebook</div>
                  <div class="clip">
                    <div
                      class="mobile-menu-nav-icon w-embed"
                      style={{
                        transform:
                          "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="margin-bottom margin-xsmall">
                <div class="opening-hours-row" style={{ cursor: "pointer" }}>
                  <div>
                    <strong>
                      <a href="https://wa.me/212672920192">
                        <FaWhatsapp color="black" size={25} />
                      </a>
                    </strong>
                  </div>
                  <div>WhatsApp</div>
                  <div class="clip">
                    <div
                      class="mobile-menu-nav-icon w-embed"
                      style={{
                        transform:
                          "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="div-block-11">
            <a
              onClick={() => navigate("/menu")}
              class="button inverse small  w-inline-block"
              style={{ cursor: "pointer" }}
            >
              <div class="button-inner">
                <div
                  class="button-inner-text"
                  style={{
                    transform:
                      "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                >
                  CARTE
                </div>
                <div
                  class="button-inner-text-hover"
                  style={{
                    transform:
                      "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                >
                  Bereken de kosten
                </div>
              </div>
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
};
