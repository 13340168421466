import React, { useEffect, useState, useContext } from "react";
import SEO from "../components/seo";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { utils } from "../utils";

import { SIZES, COLORS, FONTS } from "../constants/theme";
import CustomRadioButton from "../components/customRadioButton/customRadioButton";
import StepperInput from "../components/StepperInput";
import TextButton from "../components/TextButton";
import { CartContext } from "../core/CartProvider";
import constants from "../constants/constants";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const IndexFoodDetail = ({ item }) => {
  const navigate = useNavigate();
  const { addItemToCart, getCurrentUser } = useContext(CartContext);
  const location = useLocation();

  const currentUser = getCurrentUser();

  const [selectedValues, setSelectedValues] = useState([]);
  const [qty, setQty] = useState(1);

  const getNewPrice = () => {
    let newPrice = Number(item.price);

    selectedValues.map((element) => {
      element.value.map((value) => {
        newPrice += Number(value.price);
      });
    });

    return newPrice;
  };

  const countOfRequiredReached = () => {
    const countOfRequiredSelected = selectedValues?.filter((selectedValue) => {
      return selectedValue?.required && selectedValue?.value?.length > 0;
    })?.length;

    let countOfRequiredMustBeSelected = 0;
    item?.customChoice?.map((customChoice) => {
      customChoice.length > 0 &&
        Object.keys(customChoice[0]).map((key, index) => {
          customChoice[0][key].required && countOfRequiredMustBeSelected++;
        });
    });
    return countOfRequiredSelected === countOfRequiredMustBeSelected;
  };

  const onAddToCart = (product) => {
    addItemToCart(product);
  };

  const updateSelectedConfigValues = (key, value, required) => {
    setSelectedValues((prevState) => {
      const item = prevState.find((item) => item.key == key);
      if (!item) {
        return [
          ...prevState,
          {
            key,
            value,
            required,
          },
        ];
      } else {
        return prevState.map((item) => {
          if (item.key == key) {
            item.value = value;
          }
          return item;
        });
      }
    });
  };

  return (
    <React.Fragment>
      {/*<p>data {JSON.stringify(item)}</p>*/}
      <div style={{ marginBottom: 150, marginTop: 100, paddingBottom: 80 }}>
        {/*food image*/}
        {item.image && (
          <div
            style={{
              height: 190,
              borderRadius: 5,
              //backgroundColor:COLORS.lightGray2,
              alignItems: "center",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <img
              src={item.image}
              style={{
                height: "100%",
                width: "100%",
                borderRadius: 20,
                objectFit: "contain",
              }}
            />
          </div>
        )}

        {/*food description*/}
        <div
          style={{
            marginTop: SIZES.padding,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: -15,
            }}
          >
            <h4 style={{ width: "70%", textAlign: "center" }}>{item.name}</h4>
            <h4 style={{ textAlign: "center" }}>{item.price} Dhs</h4>
          </div>
          <p
            style={{
              color: "rgb(205 205 205 / 79%)",
              textAlign: "center",
            }}
          >
            {item.description}
          </p>
        </div>

        {/*food detail*/}
        {item.customChoice?.map((customChoice, index) => (
          <div key={index}>
            <CustomRadioButton
              data={customChoice}
              updateSelectedConfigValues={(key, value, required) =>
                updateSelectedConfigValues(key, value, required)
              }
            />
          </div>
        ))}
      </div>

      {/* actions */}
      <div>
        {countOfRequiredReached() && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              paddingHorizontal: SIZES.padding,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: SIZES.base,
                position: "fixed",
                bottom: 20,
                right: 0,
                left: 0,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <>
                <StepperInput
                  value={qty}
                  onPlus={() => countOfRequiredReached() && setQty(qty + 1)}
                  onMinus={() =>
                    countOfRequiredReached() && qty > 1 && setQty(qty - 1)
                  }
                  direction="horizontal"
                />
                <TextButton
                  buttonContainerStyle={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    height: 60,
                    borderRadius: SIZES.radius,
                    marginLeft: SIZES.radius,
                    paddingHorizontal: SIZES.padding,

                    backgroundColor: "black",
                    border: "2px solid #f7da71",
                    cursor: "pointer",
                    borderRadius: 30,
                  }}
                  labelContainerStyle={{
                    width: "60%",
                  }}
                  label={
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "#f7da71",
                        background: "rgba(255, 255, 255, 0.1)",
                        padding: "8px 14px",
                        borderRadius: 20,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Ajouter
                    </div>
                  }
                  disabled={!countOfRequiredReached()}
                  label2={
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "#f7da71",
                      }}
                    >
                      {qty * getNewPrice() + " Dhs"}
                    </div>
                  }
                  onPress={() => {
                    let customChoices = [];
                    selectedValues.map((element) => {
                      let newValues = [];

                      element.value.map((el) => {
                        newValues.push(el.value);
                      });

                      customChoices.push({
                        key: element.key,
                        value: newValues,
                      });
                    });

                    onAddToCart({
                      ...item,
                      price: getNewPrice(),
                      qty,
                      ...(customChoices.length >= 1 && {
                        categorieConfig: customChoices,
                      }),
                    });
                    navigate(
                      "/" +
                        constants.urlqrcode +
                        "/" +
                        utils.getTableNumber(location.pathname) +
                        "/MyCart/" +
                        constants.suburlqrcode
                    );
                  }}
                />
              </>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const FoodDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { state } = useLocation();
  const location = useLocation();
  const { getCurrentUser } = useContext(CartContext);
  const currentUser = getCurrentUser();
  return (
    <>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

      <div className="container">
        <div
          style={{
            position: "fixed",
            top: 10,
            left: 10,
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            gap: 8,
            backgroundColor: COLORS.white,
            padding: "8px 12px",
            borderRadius: "20px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            animation: "fadeIn 0.6s ease-out forwards", // Animation for fade-in
            transform: "scale(0.95)", // Initial scale
            transition: "transform 0.3s ease-in-out, opacity 0.6s ease-out", // Smooth scale transition
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.transform = "scale(1.05)")
          }
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          <Link
            to={
              utils.isOrderMode(location.pathname)
                ? `/${constants.urlqrcode}/${utils.getTableNumber(
                    location.pathname
                  )}/${constants.suburlqrcode}`
                : "/"
            }
            style={{
              display: "flex",
              alignItems: "center",
              color: "#666",
              fontWeight: "600",
              textDecoration: "none",
              alignContent: "center",
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#666"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M15 18l-6-6 6-6"></path>
            </svg>
            <span
              style={{
                marginLeft: 6,
                fontSize: 12,
                marginTop: 3,
                fontWeight: "bold",
              }}
            >
              RETOUR AU MENU
            </span>
          </Link>
        </div>
        <IndexFoodDetail item={state} />
      </div>
    </>
  );
};

export default FoodDetail;
