import logo from "../images/logo5.png";

import { useLocation, useNavigate } from "react-router-dom";

export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div
      class="header absolute"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div class="logo w-embed">
        <img
          src={logo}
          style={{ width: window.innerWidth <= 991 ? "20vh" : "40vh" }}
        />
      </div>
    </div>
  );
};
