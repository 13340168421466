import React, { useContext } from "react";
import { CartContext } from "../../core/CartProvider";
import { FaShoppingBag } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { utils } from "../../utils";
const CartQuantityButton = ({ containerStyle, onPress }) => {
  const { getItemsCount } = useContext(CartContext);
  const location = useLocation();
  return (
    <motion.button
      initial={{ opacity: 1, scale: 0.9 }}
      animate={{
        opacity: 1,
        scale: 1.03, // Smooth zoom without disappearing
      }}
      transition={{
        repeat: Infinity,
        repeatType: "reverse", // Instead of keyframes, smoothly reverses
        duration: 2,
        ease: "easeInOut",
      }}
      whileHover={{ scale: 1.08 }}
      style={{
        display: "flex",
        alignItems: "center",
        gap: 14,
        backgroundColor: "black",
        border: "2px solid #f7da71",
        cursor: "pointer",
        padding: "5px 6px",
        borderRadius: 30,
        position: "fixed",
        bottom: 20,
        right: 20,
        zIndex: 1000,
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.4)",
      }}
      onClick={onPress}
    >
      <motion.span
        animate={{
          opacity: [1, 0.8, 1], // Soft glow effect
          transition: { repeat: Infinity, duration: 3, ease: "easeInOut" },
        }}
        style={{
          fontSize: 16,
          fontWeight: "bold",
          color: "#f7da71",
          background: "rgba(255, 255, 255, 0.1)",
          padding: "8px 14px",
          borderRadius: 20,
          whiteSpace: "nowrap",
        }}
      >
        Table N° {utils.getTableNumber(location.pathname)}
      </motion.span>

      {/* Cart Icon */}
      <motion.div
        animate={{
          scale: [1, 1.08, 1], // Smooth zoom effect
          transition: { repeat: Infinity, duration: 4, ease: "easeInOut" },
        }}
        style={{
          width: 50,
          height: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#f7da71",
          borderRadius: "50%",
          position: "relative",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        <FaShoppingBag color="black" size={26} />

        {/* Animated Badge */}
        {
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            style={{
              position: "absolute",
              top: -5,
              right: -5,
              height: 22,
              width: 22,
              backgroundColor: "white",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)",
              border: "2px solid #f7da71",
            }}
          >
            <p
              style={{
                color: "black",
                fontSize: 17,
                fontWeight: "bold",
                marginBottom: 0,
                marginTop: "3px",
              }}
            >
              {getItemsCount()}
            </p>
          </motion.div>
        }
      </motion.div>
    </motion.button>
  );
};

export default CartQuantityButton;
