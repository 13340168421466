import { COLORS, SIZES } from "../constants/theme";
import constants from "../constants/constants";
import React, { useContext, useState, useEffect } from "react";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import firebase from "../utils/FirebaseInfo.js";
import { promiseWithTimeout } from "../core/api";
import { useMutation } from "react-query";
import MyComponent from "react-fullpage-custom-loader";

import StepperInput from "../components/StepperInput";
import { CartContext } from "../core/CartProvider";
import { utils } from "../utils";
import { ErrorPage } from "../pages/ErrorPage";
import emptystore from "../images/empty_store.png";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FooterTotal from "../components/FooterTotal/FooterTotal";
import "./styles.css";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const MyCart = () => {
  const { items, updateQuantityHandler, removeItem } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [formattedItemsList, setFormattedItemsList] = useState([]);
  const { removeAllItems } = useContext(CartContext);
  const { getTotalPrice, getCurrentUser } = useContext(CartContext);
  const currentUser = getCurrentUser();

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={() => console.info("swipe action triggered")}>
        Action name
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        onClick={() => console.info("swipe action triggered")}
      >
        Delete
      </SwipeAction>
    </TrailingActions>
  );

  const styles = {
    cartItemContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: SIZES.radius,
      paddingHorizontal: SIZES.radius,
      borderRadius: SIZES.radius,
    },
  };

  const getCustomKey = (item) => {
    let newKey = item.product.name + item.product?.comment;
    item.product?.categorieConfig?.map((catConf) => {
      newKey += catConf.value;
    });
    return newKey;
  };
  const [status, setStatus] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);

  const countCustomChoice = (item) => {
    let count = 0;
    item?.product?.categorieConfig?.map((catConfig, index) =>
      catConfig?.value?.map((catConf) => count++)
    );
    return count;
  };

  const navigate = useNavigate();

  const fetchTokens = () => {
    return firebase
      .ref("/Users")
      .once("value")
      .then((snapshot) => {
        let data = [];
        snapshot.forEach((subChildsnap) => {
          if (
            subChildsnap.val().role === "admin" ||
            subChildsnap.val().role === "cuisinier"
          )
            data.push(subChildsnap.val().token);
        });
        return data;
      });
  };

  const sendNotif = async () => {
    const tokens = await fetchTokens();
    const currentDate = await utils.fetchCurrentTime();
    const message = {
      registration_ids: tokens,
      notification: {
        title: "Nouvelle commande",
        body:
          "Une nouvelle commande d'un montant de " +
          getTotalPrice() +
          " Dhs est lancée à " +
          utils.formatDate(currentDate),
        vibrate: 1,
        sound: 1,
        show_in_foreground: true,
        priority: "high",
        content_available: true,
      },
    };

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization:
        "key=	AAAALnUKSnQ:APA91bEHYzu0sL95BGqzwKingY-YyIgyFK_F0xyPNxzY3wkpNojff40hKbRZbGEv9b3nVwb8RdgWc3CyfaU2DbVdfo2u3L4wuwH4JLwv1NjxP-3BjgpUiY_iQ_gEf3ITAtWZB8jK3Xzp",
    });
    //console.log('message sent from rest call', message);
    try {
      let response = await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers,
        body: JSON.stringify(message),
      });
      response = await response.json();
      console.log("response ", response);
    } catch (error) {
      console.log("error ", error);
    }
  };

  function onDeleteAll() {
    //console.log("Deleted all history");
  }

  function deleteAllHistory() {
    /*
    let deletingAll = chrome.history.deleteAll();
    deletingAll.then(onDeleteAll);*/
  }

  deleteAllHistory();

  const updateUserMutation = useMutation(
    async (data) => {
      if (!status) {
        //Alert.alert("Aucune connexion internet","Veuillez vérifier votre accès à internet.")
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div
                className="custom-ui"
                style={{ padding: "20px", textAlign: "center" }}
              >
                <h1 style={{ color: COLORS.black }}>Oups!</h1>
                <p style={{ color: COLORS.black }}>
                  Veuillez vérifier votre accès à internet et réessayer
                  ultérieurement
                </p>
                <button
                  onClick={onClose}
                  style={{
                    margin: 5,
                    border: "none",
                    padding: 15,
                    backgroundColor: "black",
                    color: "#f7da72",
                    fontWeight: "bold",
                  }}
                >
                  OK
                </button>
              </div>
            );
          },
          closeOnClickOutside: false,
        });
        throw new Error("Connection error");
      }
      return promiseWithTimeout(
        Promise.resolve(firebase.ref("/Orders").push(data)),
        10000
      );
    },

    {
      onSuccess: async () => {
        deleteAllHistory();
        removeAllItems();
        await sendNotif();
        setIsLoading(false);

        navigate("/");

        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div
                className="custom-ui"
                style={{ padding: "20px", textAlign: "center" }}
              >
                <h1 style={{ color: COLORS.black }}>Commande reçue</h1>
                <p style={{ color: COLORS.black }}>
                  Veuillez scanner à nouveau le QR code si vous voulez passer
                  une nouvelle commande
                </p>
                {/* <p style={{ color: COLORS.black }}>
                  On vous contactera par téléphone en cas de besoin
                </p> */}
                <h3 style={{ color: COLORS.black }}>Keep in touch 😉</h3>
                <button
                  style={{
                    margin: 5,
                    border: "none",
                    padding: 15,
                    backgroundColor: "black",
                    color: "#f7da72",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Consulter notre menu
                </button>
              </div>
            );
          },
          closeOnClickOutside: false,
        });
      },
      onError: (error) => {
        setIsLoading(false);

        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div
                className="custom-ui"
                style={{ padding: "20px", textAlign: "center" }}
              >
                <h1 style={{ color: COLORS.black }}>Oups!</h1>
                <p style={{ color: COLORS.black }}>
                  Veuillez vérifier votre accès à internet et réessayer
                  ultérieurement
                </p>
                <button
                  onClick={onClose}
                  style={{
                    margin: 5,
                    border: "none",
                    padding: 15,
                    backgroundColor: "black",
                    color: "#f7da72",
                    fontWeight: "bold",
                  }}
                >
                  OK
                </button>
              </div>
            );
          },
          closeOnClickOutside: false,
        });
      },
    }
  );

  const [isRadio, setIsRadio] = useState(null);

  const handleChange = (e) => {
    // string passed in
    // a string returned by default
    console.log(e);
    // add + to the event to make the value a number
    setIsRadio(e);
  };

  const renderSwiperList = () => {
    return (
      <>
        {items.length === 0 ? (
          <div style={{ display: "flex", flex: 1 }}>
            <ErrorPage
              resetError={() =>
                navigate(
                  "/" +
                    constants.urlqrcode +
                    "/" +
                    utils.getTableNumber(location.pathname) +
                    "/" +
                    constants.suburlqrcode
                )
              }
              image={emptystore}
              title="Oups !"
              subtitle="Votre panier est vide"
              buttonLabel={"Consulter notre menu"}
            />
          </div>
        ) : (
          <SwipeableList>
            {items?.map((item) => (
              <SwipeableListItem
                key={getCustomKey(item)}
                style={{
                  marginTop: SIZES.radius,
                  paddingBottom: SIZES.padding * 2,
                  display: "block",
                }}
              >
                <div
                  style={{
                    paddingLeft: SIZES.padding,
                    paddingRight: SIZES.padding,
                    paddingTop: SIZES.padding / 2,
                    minHeight:
                      (item.product?.comment ? 155 : 115) +
                      countCustomChoice(item) * 10,
                    backgroundColor: "rgb(30, 30, 30)",
                    width: "100%",
                    ...styles.cartItemContainer,
                  }}
                >
                  <div
                    style={{
                      width: "30%",
                      height: 100,
                      marginLeft: -5,
                      marginRight: 5,
                    }}
                  >
                    {item.product.image ? (
                      <img
                        src={item.product.image}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div
                    style={{
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          width: "45%",
                          marginBottom: 0,
                          fontWeight: 400,
                        }}
                      >
                        {item.product.name}
                      </p>
                      <StepperInput
                        containerStyle={{
                          height: 50,
                          width: SIZES.width * 0.3,
                        }}
                        value={item.qty}
                        onPlus={() =>
                          updateQuantityHandler(item.qty + 1, item.product)
                        }
                        onMinus={() => {
                          if (item.qty > 1) {
                            updateQuantityHandler(
                              item.qty - 1,
                              item.product,
                              true
                            );
                          } else {
                            confirmAlert({
                              customUI: ({ onClose }) => {
                                return (
                                  <div
                                    className="custom-ui"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <h1 style={{ color: COLORS.black }}>
                                      Attention
                                    </h1>
                                    <p style={{ color: COLORS.black }}>
                                      Êtes-vous sûr de vouloir supprimer ce
                                      produit ?
                                    </p>
                                    <button
                                      onClick={onClose}
                                      style={{
                                        margin: 5,
                                        border: "none",
                                        padding: 15,
                                        backgroundColor: "white",
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Annuler
                                    </button>
                                    <button
                                      style={{
                                        margin: 5,
                                        border: "none",
                                        padding: 15,
                                        backgroundColor: "black",
                                        color: "#f7da72",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        removeItem(item.product);
                                        onClose();
                                      }}
                                    >
                                      Confirmer
                                    </button>
                                  </div>
                                );
                              },
                              closeOnClickOutside: false,
                            });
                            /*Alert.alert(
                                    'Attention',
                                    'Êtes-vous sûr de vouloir supprimer ce produit ?',
                                    [
                                    {
                                        text: 'Confirmer',
                                        onPress: async () => {
                                        removeItem(item.product)
                                        },
                                    },
                                    {
                                        text: 'Annuler',
                                        onPress: () => console.log('OK Pressed'),
                                        style: 'cancel',
                                    },
                                    ],
                                );*/
                          }
                        }}
                      />
                    </div>
                    <p
                      style={{
                        color: COLORS.primary,
                        marginBottom: 0,
                        color: "#f7da72",
                        fontWeight: 400,
                      }}
                    >
                      {item.product.price} Dhs
                    </p>
                    <div>
                      <p style={{ fontSize: 11 }}>
                        {utils.displayExtrasItem(item.product.categorieConfig)}
                      </p>

                      {item?.product?.comment?.length > 0 && (
                        <p
                          style={{
                            marginTop: -5,
                            fontSize: 10,
                            fontStyle: "italic",
                            textTransform: "uppercase",
                          }}
                        >
                          * {item.product?.comment}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </SwipeableListItem>
            ))}
          </SwipeableList>
        )}
      </>
    );
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.black,
        marginBottom: 300,
        paddingBottom: 300,
      }}
    >
      {isLoading ? (
        <MyComponent sentences={["Merci de patienter ..."]} />
      ) : (
        <>
          {renderSwiperList()}

          {items.length > 0 && (
            <>
              <FooterTotal
                isLoading={isLoading}
                disabled={isLoading}
                handleChange={handleChange}
                isRadio={isRadio}
                labelConfirm={"Finaliser ma commande"}
                onAddNewItem={() =>
                  navigate(
                    "/" +
                      constants.urlqrcode +
                      "/" +
                      utils.getTableNumber(location.pathname) +
                      "/" +
                      constants.suburlqrcode
                  )
                }
                onConfirm={(isRadio) => {
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div
                          className="custom-ui"
                          style={{ padding: "20px", textAlign: "center" }}
                        >
                          <h1 style={{ color: COLORS.black }}>Attention</h1>
                          <p style={{ color: COLORS.black }}>
                            Êtes-vous sûr de vouloir confirmer cette commande ?{" "}
                          </p>
                          <p style={{ color: COLORS.black }}>
                            Aucune modification ne peut être appliquée sur votre
                            commande après confirmation.
                          </p>
                          {isRadio === "surplace" ? (
                            <p
                              style={{
                                padding: 5,
                                color: "white",
                                borderRadius: 20,
                                color: COLORS.black,
                                fontWeight: 400,
                                border: "1px solid #0000003d",
                              }}
                            >
                              Commande à table
                              {utils.getTableNumber(location.pathname) === "0"
                                ? " depuis le comptoir"
                                : " N° " +
                                  utils.getTableNumber(location.pathname)}
                            </p>
                          ) : (
                            <p
                              style={{
                                color: COLORS.black,
                                fontWeight: 400,
                                border: "1px solid #0000003d",
                                padding: 5,
                                borderRadius: 20,
                              }}
                            >
                              Commande à emporter depuis{" "}
                              {utils.getTableNumber(location.pathname) === "0"
                                ? "le comptoir"
                                : "la table N° " +
                                  utils.getTableNumber(location.pathname)}
                            </p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={onClose}
                              style={{
                                margin: 5,
                                border: "none",
                                padding: 15,
                                backgroundColor: "white",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              Vérifier ma commmande
                            </button>
                            <button
                              style={{
                                margin: 5,
                                border: "none",
                                padding: 15,
                                backgroundColor: "black",
                                color: "#f7da72",
                                fontWeight: "bold",
                              }}
                              onClick={async () => {
                                setIsLoading(true);
                                onClose();
                                const currentDate =
                                  await utils.fetchCurrentTime();

                                let newItemsList = [];

                                items.forEach((element) => {
                                  let newElement = {};
                                  newElement["totalPrice"] = element.totalPrice;
                                  newElement["product"] = {
                                    categorieConfig:
                                      element.product?.categorieConfig || [],
                                    name: element.product.name,
                                    price: element.product.price,
                                    qty: element.qty,
                                  };
                                  newItemsList.push(newElement);
                                });

                                let orderData = {
                                  userUID: "GUEST",
                                  createdAt: JSON.stringify(currentDate),
                                  totalPrice: getTotalPrice(),
                                  items: newItemsList,
                                  phoneNumber: "0xxxxxxxxx",
                                  address: "HOLA Blanca",
                                  statut: constants.track_order_status[0],
                                  userName: "client hola",
                                  // currentUser.name +
                                  // " " +
                                  // currentUser.firstName,
                                  email: "guest@gmail.com",
                                  delivery: {
                                    deliveryType: isRadio,
                                    tableNumber: utils.getTableNumber(
                                      location.pathname
                                    ),
                                  },
                                };
                                updateUserMutation.mutate(orderData);
                              }}
                            >
                              Confirmer ma commande
                            </button>
                          </div>
                        </div>
                      );
                    },
                    closeOnClickOutside: false,
                  });
                }}
              />

              {}
            </>
          )}
        </>
      )}
    </div>
  );
};

const MyCartContainer = () => {
  const { getCurrentUser } = useContext(CartContext);
  const currentUser = getCurrentUser();
  const top = currentUser && currentUser.tableName ? 145 : 80;
  const location = useLocation();

  return (
    <Layout withFooter={false} hideAction={true}>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div className="container">
        <div
          style={{
            position: "fixed",
            top: 10,
            left: 10,
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            gap: 8,
            backgroundColor: COLORS.white,
            padding: "8px 12px",
            borderRadius: "20px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            animation: "fadeIn 0.6s ease-out forwards", // Animation for fade-in
            transform: "scale(0.95)", // Initial scale
            transition: "transform 0.3s ease-in-out, opacity 0.6s ease-out", // Smooth scale transition
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.transform = "scale(1.05)")
          }
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          <Link
            to={
              utils.isOrderMode(location.pathname)
                ? `/${constants.urlqrcode}/${utils.getTableNumber(
                    location.pathname
                  )}/${constants.suburlqrcode}`
                : "/"
            }
            style={{
              display: "flex",
              alignItems: "center",
              color: "#666",
              fontWeight: "600",
              textDecoration: "none",
              alignContent: "center",
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#666"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M15 18l-6-6 6-6"></path>
            </svg>
            <span
              style={{
                marginLeft: 6,
                fontSize: 12,
                marginTop: 3,
                fontWeight: "bold",
              }}
            >
              RETOUR AU MENU
            </span>
          </Link>
        </div>
        <MyCart />
      </div>
    </Layout>
  );
};

export default MyCartContainer;
